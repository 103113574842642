import { onMounted, computed } from 'vue'
// import { getQsTimelinessInfo } from 'public/src/pages/cart_new/utils/qsTagInfo.js'
import { useMapState, useMapMutation as useMapMutations, useMapActions } from 'public/src/pages/store_pages/store/mapHook'
import { template } from '@shein/common-function'
// import schttp from 'public/src/services/schttp'

export const useQuickShipInit = () => {
  const { checkout, quickShipInfoTag } = useMapState([ 'checkout', 'quickShipInfoTag', 'storeData', 'buyNowNo' ])
  const { assignState } = useMapMutations(['assignState'])
  const { newQuickShip } = useMapActions(['newQuickShip'])

  const quickShip = async () => {
    const { OrderQuickshipStyle } = checkout.value.checkoutBFFAbtInfo   || {}
    const orderQuickshipStyleStatus = OrderQuickshipStyle?.param?.OrderQuickshipStyleStatus == 1
    if(!quickShipInfoTag.value?.qsDataProps && !quickShipInfoTag.value?.qsDataNoLabelProps){
      // const result = await getQsTimelinessInfo( checkout.value.default_address )
      await newQuickShip()
      const qsDataProps = {
        isShowNewStyle: orderQuickshipStyleStatus, // 是否展示新样式，即带有icon + x 日达 | 老样式
        // text: result.quickShipText, // qs标签标题（前半部分内容）
        noLableStyle: false, // 是否需要lable标签样式
        labelStyle: { margin: 0 },
        // qs标签时效信息（后半部分内容）1.新样式 2. isLocationTop = false 3.tip有值
        qsTimelinessInfo: {
          isLocationTop: true,
          // tip: result?.info
        }
      }
      const qsDataNoLabelProps = { ...qsDataProps, noLableStyle: true }
      assignState({ quickShipInfoTag: {
        qsDataProps,
        qsDataNoLabelProps
      },
      // qsMallList: result.qs_mall_list
      })
    }
  }

  // const mall_list = (carts) => {
  //   // id 标识，goods_sn => skc, sku_code => sku
  //   return carts.map(item => {
  //     const result = {
  //       mall_code: item.mall_code,
  //       store_code: item.store_code,
  //       item_no: item.id,
  //       skc_code: item.product.goods_sn,
  //       sku_code: item.product.sku_code
  //     }
  //     return result
  //   })
  // }

  // const newQuickShip = async(scene) => {
  //   try {
  //     const address = checkout.value.default_address
  //     const carts = checkout.value.results.carts.carts
  //     const params = {
  //       addressInfoOther: address,
  //       has_shop_transit_address: !!storeData.value ? 1 : 0, checkout_no: !!buyNowNo.value, scene: scene || 'checkout',
  //       mall_list: mall_list(carts)
  //     }
  //     const qsTimelinessInfo = await schttp({
  //       url: '/api/common/getNewQsTimeLineInfoSerCon/get',
  //       method: 'POST',
  //       data: params
  //     })
  //     return qsTimelinessInfo
  //   } catch (err) {
  //     console.error(`[cart getQsTimelinessInfo] 获取qs信息失败: ${err}`)
  //   }
  // }

  onMounted(() => {
    // typeof window != 'undefined' && quickShip()
    typeof window != 'undefined' && quickShip()
  })
  return {}
}

export const useGetGuickShip = () => {
  const { quickShipInfoTag, qsMallList, checkout, language, defaultShipping } = useMapState([ 'quickShipInfoTag', 'qsMallList', 'descQuickShipTime', 'checkout', 'language', 'defaultShipping' ])

  // 对部分qs时效提示进行内容组装
  const qsReplenishDes = computed(() => {
    const { qs_time_same, last_quickship_tag, combine_transport_time_desc_replace, allSceneQuickshipWordStatus  } = qsMallList.value || {}
    const qsReplenishDesAbt = checkout.value.checkoutBFFAbtInfo?.TradeTransStoreTest?.param?.TradeTransStoreTestStatus || 0

    let result = ''
    const item = defaultShipping.value?.[0] || {}
    if(item.desc_quick_ship_time){
      result = item.desc_quick_ship_time.replace(/script/g, '').replace(/\s/g, ' ').replace(/&nbsp;/g, ' ')
      last_quickship_tag && (result = item.desc_quick_ship_time.replace(/{QSname}/g, `${last_quickship_tag}`)
        .replace(/\[time\]/g, `${item.quick_ship_time}`))
    }
    // 对部分qs时效提示进行内容组装
    if(qs_time_same == 0){
      result = qsReplenishDesAbt == 0 
        ? template(last_quickship_tag, language.value.SHEIN_KEY_PC_33031) 
        : qsReplenishDesAbt == 2 ? combine_transport_time_desc_replace : result
    } else {
      result = allSceneQuickshipWordStatus == 'on' ? combine_transport_time_desc_replace || result || '' : result || ''
    }
    
    return result
  })

  const Need_Qs_Freight = () => computed(() => {
    return [ 108 ].includes(+checkout.value?.default_address?.countryId)
  })

  return { quickShipInfoTag, qsMallList, qsReplenishDes, Need_Qs_Freight }
}
